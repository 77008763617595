import { Controller } from "@hotwired/stimulus"
import { strToDom } from '../utils/strToDom';

export default class extends Controller {
    static targets = ['nextBtn', 'resultsBtn'];

    connect() {
        if (document.querySelectorAll('input[type="hidden"][name^="selected_answers"]').length === 0) {
            let answers = sessionStorage.getItem("selectedAnswers");
            if (answers) {
                const $form = document.querySelector('form[name="form-questions"]');
                answers = JSON.parse(answers);
                for (let key in answers) {
                    let $selectedAnswer = strToDom(`<input type="hidden" name="selected_answers[][${key}]" id="selected_answers__${key}" autocomplete="off">`);
                    $selectedAnswer.setAttribute("value", JSON.stringify(answers[key]));
                    $form.appendChild($selectedAnswer);
                };

                // Select the answer
                const question_id = parseInt(document.querySelector('#question_id').value);
                if (answers[question_id]) {
                    const answer_id = parseInt(answers[question_id]['answer_id']);
                    let radio_button = document.querySelector(`input[id="answers[][${question_id}]_${answer_id}"`);
                    $(radio_button).prop("checked", true);
                }

                const page_count = parseInt(document.querySelector('#page_count').value);
                const current_page = parseInt(document.querySelector('#current_page').value);
                if (current_page < page_count && answers[question_id]) {
                    // show next button in wizard heading
                    let $next_btn = strToDom(`<button name="page" type="submit" value="${current_page + 1}" class="btn btn-link px-0 py-0 icon-link d-none d-md-inline-block" aria-title="Volgende vraag"><span class="icon-chevron-right fs-8 align-baseline"></span></button>`);
                    const $wizard_header = document.querySelector('#wizard-heading');
                    $wizard_header.appendChild($next_btn);
                    // enable next button in wizard actions
                    this.nextBtnTarget.disabled = false;
                }
                
                // enable & show result button in wizard actions
                if (Object.keys(answers).length === page_count) {
                    this.resultsBtnTarget.disabled = false;
                    $(this.resultsBtnTarget).removeClass('d-none');
                    $(this.resultsBtnTarget).addClass('d-md-inline-block');
                }
            }
        }
    }

    select(event) {
        event.preventDefault();

        const value = event.currentTarget.value.trim();
        const numSelectedAnswers = document.querySelectorAll('.d-print-none input[name^="selected_answers"]').length + 1;
        const numPages = parseInt(document.querySelector('#page_count').value);
        const currPage = parseInt(document.querySelector('#current_page').value);
        const question_id = parseInt(document.querySelector('#question_id').value);

        if (value) {
          if (numSelectedAnswers === numPages) {
              this.resultsBtnTarget.disabled = false;
              $(this.resultsBtnTarget).removeClass('d-none');
              $(this.resultsBtnTarget).addClass('d-md-inline-block');
          } else {
              this.nextBtnTarget.disabled = false;
          }

          let answers = sessionStorage.getItem("selectedAnswers");
          if (!answers) {
            answers = {};
          } else {
            answers = JSON.parse(answers);
          }
          answers[question_id] = {answer_id: parseInt(value), page: currPage};
          sessionStorage.setItem("selectedAnswers", JSON.stringify(answers));
        }
    }
}
